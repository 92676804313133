<template>
  <div class="layout-settings">
    <div class="layout-settings__container tw-gap-3">
      <div class="layout-settings__left" >
        <h3>
            <span @click="$emit('closeSettings')">
              <svg-icon name="solid/arrow-left" />
            </span> &nbsp; Custom layout
        </h3>

          <InputGroup
            :data="{ label: 'Layout name',
              type: 'text',
              required: true,
              error: '',
              value: customLayout.name}"
            v-model="customLayout.name"
          ></InputGroup>

          <InputGroup
            :data="{ label: 'Total number of panels',
              placeholder: 'Enter number of panels',
              type: 'number',
              min: 1,
              max: 99,
              required: true,
              error: '',
              value: customLayout.number_of_sections}"
            :filter="numberFilter"
            v-model="customLayout.number_of_sections"
          ></InputGroup>

          <InputGroup
            :data="{ label: 'Number of security panels',
              placeholder: 'Enter number of security panels',
              type: 'number',
              min: 1,
              max: customLayout.number_of_sections,
              required: true,
              error: '',
              value: customLayout.number_of_security_panels}"
            :filter="numberFilter"
            v-model="customLayout.number_of_security_panels"
            @update:modelValue="panelsChange"
          ></InputGroup>

          <div class="layout_settings__title">
            Opening panels are:
            <RadioGroupItems
              class="layout_settings__panel"
              :items="isAllSectionsSameSizeOptions"
              v-model="customLayout.is_make_size_only"
            />
          </div>


          <button  @click="next" class="btn btn_default">
            <svg-icon name="solid/floppy-disk" />
            Save & Next
          </button>
        </div>

      <div class="layout-settings__right">
        <div class="layout-settings__title">Panel size formulas</div>

        <div class="layout-settings__row"  v-for="(item, index) in customLayout.icon" :key="`layout-settings-row-${index}`">
          <div class="tw-row-span-2 tw-col-start-0 tw-col-end-1 tw-px-3 tw-items-center tw-flex">
            <span>{{ index + 1 }}.</span>
          </div>
          <div class="tw-col-start-1 tw-col-span-5 tw-py-3 tw-w-full">

            <FormulaInputGroup
              class="layout-settings__item"
              :data="{ label: 'Width',
                required: true,
                error: '',
                value: customLayout.icon[index][0].default_formula_width,
                disabled: customLayout.icon[index][0].disabled
              }"
              :item="item"
              :placeholders="['Overall drop', 'Overall width', 'Total number of panels', 'Number of Opening Panels']"
              v-model="customLayout.icon[index][0].default_formula_width"
              @update:modelValue="getSizeLogic(customLayout.is_make_size_only)"
            />

            <FormulaInputGroup
              class="layout-settings__item"
              :data="{ label: 'Drop',
                required: true,
                error: '',
                value: customLayout.icon[index][0].default_formula_drop,
                disabled: customLayout.icon[index][0].disabled
              }"
              :item="item"
              :placeholders="['Overall drop', 'Overall width', 'Total number of panels', 'Number of Opening Panels']"
              v-model="customLayout.icon[index][0].default_formula_drop"
              @update:modelValue="getSizeLogic(customLayout.is_make_size_only)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {ref, computed, watch} from 'vue'
import InputGroup from "../../../components/form/InputGroup"
import PanelOpeningInputSize from '../../../components/form/PanelOpeningInputSize'
import FormulaSelection from '../../../components/form/FormulaSelection'
import FormulaInputGroup from '../../../components/form/FormulaInputGroup'
import RadioGroupItems from '../../form/RadioGroupItems'
import api from '../../../services/customLayouts.service'

export default {
  name: "Settings",
  components: {InputGroup, PanelOpeningInputSize, FormulaSelection, FormulaInputGroup, RadioGroupItems},
  props: ['item', 'allData'],
  setup(props, {emit}){
    const customLayout = ref (props.item ||{
      id: 0,
      name: '',
      number_of_sections: 1,
      number_of_security_panels: 1,
      icon: [[
        {
          default_formula_width: '',
          default_formula_drop: '',
          number: 1
        }
      ]],
      is_visible_on_quoting_portal: false,
      is_make_size_only: true,
      total_rows: 0,
      total_columns: 0
    })


    const isAllSectionsSameSizeOptions = [
      {
        value: 1, label: 'Same size', key:  'same'
      },
      {
        value: 0, label: 'Different', key: 'different'
      }
    ]

    watch(() => customLayout.value.number_of_security_panels, async (value) => {
      checkNumberOfPanels()
    })

    watch(() => customLayout.value.number_of_sections, async (value) => {
      checkNumberOfPanels()
    })

    watch(() => customLayout.value.is_make_size_only, async (value) => {
      getSizeLogic(value)
    })

    watch(() => customLayout.value.icon, async (value) => {
      getSizeLogic(value)
    })

    function checkNumberOfPanels(){
       if (parseInt(customLayout.value.number_of_security_panels) > parseInt(customLayout.value.number_of_sections)) {
         customLayout.value.number_of_security_panels = customLayout.value.number_of_sections
       }
    }

    function getSizeLogic(value){
      const dropFirstSection = customLayout.value.icon[0][0].default_formula_drop
      const widthFirstSection = customLayout.value.icon[0][0].default_formula_width

      customLayout.value.icon.forEach((el, index) => {
        if(index) {
          if (value) {
            el[0].default_formula_drop = dropFirstSection
            el[0].default_formula_width = widthFirstSection
          }
          el[0].disabled = value
        }
      })
    }
    getSizeLogic(customLayout.value.is_make_size_only)

    function panelsChange() {
      let a = customLayout.value.icon

      while (a.length < customLayout.value.number_of_security_panels){
        a.push([{
          default_formula_width: '',
          default_formula_drop: '',
          number: a.length + 1
        }])
      }

      customLayout.value.icon = a.splice(0, customLayout.value.number_of_security_panels)
    }

    function validate(){

      let error = false

      error = !customLayout.value.name

      customLayout.value.icon.forEach(i => {
        error = error || !i[0].default_formula_drop || !i[0].default_formula_width
      })

      return !error
    }

    async function next(){

      const valid = await validate()

      if (!valid) return

      const typeStep = props.allData.steps.filter((s) => {return s.name === 'Type'})[0]
      let layout = null

      if (customLayout.value.id){
        layout = await api.update(typeStep.data.id, customLayout.value.id, customLayout.value)
        emit('submit', customLayout.value)
      }else {
        layout = await api.create(typeStep.data.id, customLayout.value)
        emit('submit', layout.data)
      }

    }

    return {
      customLayout,
      isAllSectionsSameSizeOptions,
      panelsChange,
      next,
      getSizeLogic
    }
  }
}
</script>

<style scoped lang="scss">

.layout-settings {
  position: relative;
  height: calc(100% - 115px);
  &__container {
    display: flex;
    height: 100%;
    padding-bottom: 15px;
  }
  &__left {
    margin-right: 43px;
  }
  &__right {
    @media (max-width: 768px) {
      margin-top: 0!important;
      width: 100%;
    }
    width: 100%;
    max-height: 100%;
    overflow-y: scroll;
    &__container {
      display: flex;
      flex-direction: row;
      &__item {
        flex-grow: 1;
        margin: 6px;
      }
    }
  }
  &__row {
    @media (max-width: 768px) {
      flex-wrap: nowrap!important;
      margin-top: 6px!important;
      font-size: 18px!important;
    }
    font-size: 24px;
    line-height: 27px;
    display: flex;
    align-items: center;
    margin-top: 30px;

    &__item {
      @media (max-width: 768px) {
        margin: 0 10px!important;
      }
      margin-right: 17px;
      margin-left: 11px;
      width: 100%;
    }

  }
  &__overall {
    display: flex;
    .overall {
      &__input {
        width: 170px;
        margin-right: 24px;
      }
    }
  }
  &__panel {
    display: flex;
    .panel {
      &__item {
        width: 100px;
        margin-right: 37px;
      }
    }
  }
  &__title {
    @media (max-width: 768px) {
      margin-bottom: 5px;
    }
    font-size: 18px;
    line-height: 20px;
    text-transform: uppercase;
    text-align: left;
  }
  &__example {
    display: flex;
    justify-content: flex-start;
    margin-top: 60px;
    width: fit-content;
    position: relative;
    .example {
      &__line {
        &_width, &_height {
          position: absolute;
        }
        &_width {
          top: -20px;
          // width: 100%;
        }
        &_height {
          right: -20px;
          // height: 100%;
        }
      }
    }
    // ::v-deep svg{
    //     font-size: 150px;
    // }
  }
  @media(max-width: 768px) {
    &__left {
      margin-right: 0;
    }
    &__container {
      flex-direction: column;
    }
    &__right {
      margin-top: 20px;
    }
    &__row {
      flex-wrap: wrap;
    }
  }

}

.change_order{
  order: 1;
}



</style>
