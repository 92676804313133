<template>
    <div class="items__container" v-if="items && !showSettings">
        <div class="item__wrapper"
            v-for="(item, index) in items.data.filter(i => {return !i.is_custom})"
            :key="`layout-item-${item.id}${index}`" >
            <Item
                :item="item"
                @selected="$emit('submit', item)"
            />
        </div>
      <div v-if="!isQuoting">
        <div class="item__wrapper"
             v-for="(item, index) in items.data.filter(i => {return i.is_custom})"
             :key="`layout-item-${item.id}${index}`" >
          <Item
            :item="{
            id: item.id,
            name: item.name,
            icon: 'custom-layout.svg',
            edit: true,
            delete: false
            }"
            @edit="displaySettings(item)"
            @selected="$emit('submit', item)"
          />
<!--          <div @click="displaySettings(item)">-->
<!--            <svg-icon name="solid/cog" />-->
<!--          </div>-->
        </div>

<!--        <div class="item__wrapper">-->
<!--          <Item-->
<!--            :item="customLayoutItem"-->
<!--            @selected="displaySettings()"-->
<!--          />-->
<!--        </div>-->
      </div>
    </div>
    <settings :item="customId" v-else-if="showSettings" @closeSettings="closeSettings"></settings>
</template>


<script>
import Item from './elements/Item'
import Loader from '../elements/Loader.vue'
import {ref} from "vue";
import Settings from './custom-layouts/Settings'

export default({
    components: {
        Item,
        Loader,
      Settings
    },
    props: ['items', 'isQuoting'],
    setup(props, { emit }) {
      const customLayoutItem = ref({
        name: 'Create Custom Layout',
        icon: 'custom-layout.svg'
      })
      const showSettings = ref (false)
      const customId = ref ( 0)


      function displaySettings(id){
        customId.value = id
        showSettings.value = true
      }

      function closeSettings(){
        console.log('dsfsdaf')
        customId.value = null
        showSettings.value = false
      }


      return {
        customLayoutItem,
        showSettings,
        customId,
        displaySettings,
        closeSettings
      }
    },



  })
</script>

<style scoped lang="scss">
.items__container {

    min-height: auto!important;
    @media(max-width: 768px) {
        margin-top: 0;
    }
}
::v-deep .icons, ::v-deep  .item__icon {
    max-height: none;
}
</style>
