import { AJAX_POST, AJAX_GET, AJAX_PUT } from '../helpers/ajax'

export default {
    async create(type_id, payload) {
        return await AJAX_POST(`/custom-layouts/types/${type_id}`, payload)
    },
    async update(type_id, layout_id, payload) {
        return await AJAX_POST(`/custom-layouts/types/${type_id}/${layout_id}`, payload)
    },

}
