<script setup>

import {Popover, PopoverButton, PopoverPanel} from "@headlessui/vue";
import {computed} from "vue"

// eslint-disable-next-line no-undef
const emits = defineEmits(['formulaSelected'])
// eslint-disable-next-line no-undef
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'width'
  },
  placeholders: {
    type: Array,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: []
  }
})

const getRelatedDropDownItemsBasedOnGivenType = computed(() => {
  return props.placeholders
})

</script>
<template>
  <div>
    <Popover v-slot="{ open }" class="popover">
      <PopoverButton :disabled="disabled" as="button"
                     class="btn btn_default popover-button tw-p-0 tw-border-0">
        <div class="tw-space-x-1.5">
                <span v-if="open">
                    <svg-icon name="solid/chevron-up"></svg-icon>
                </span>
          <span v-else>
                    <svg-icon name="solid/chevron-down"></svg-icon>
                </span>
        </div>
      </PopoverButton>

      <transition name="popover">
        <PopoverPanel v-slot="{ close }" class="popover-panel tw-w-28 tw-bg-gray-100- tw-shadow-lg">
          <div
            class="tw-grid tw-grid-cols-1 tw-gap-1 tw-text-sm tw-bg-gray-300- tw-divide-y tw-divide-red-500">
            <button v-for="(formula, k) in getRelatedDropDownItemsBasedOnGivenType"
                    :key="k"
                    class="btn btn_default popover-menu-item"
                    @click="
                    () => {
                        emits('formulaSelected', formula)
                        close();
                    }
                    "
            >
              {{ formula }}
            </button>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>
